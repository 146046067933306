import '@assets/scss/pages/project.scss';

import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';

// components
import Layout from '@src/layouts';
import Video from '@components/atoms/Video';
// import Blockquote from '@components/molecules/Blockquote';
import ButtonExternal from '@components/atoms/buttons/ButtonExternal';
// import ButtonSecondary from '@components/atoms/buttons/ButtonSecondary';
import Column from '@components/organisms/Column';
// import Cta from '@components/molecules/Cta';
// import Gallery from '@components/organisms/Gallery';
import HeroProject from '@components/molecules/heros/HeroProject';
import Image from '@components/atoms/Image';
// import ItemArticle from '@components/molecules/items/ItemArticle';
import ListText from '@components/organisms/ListText';

// --------------------------------
// #region data
// --------------------------------

const language = 'fr';

const pageClass = 'page-project';

const accent_color = '#E80435';
const footerRelatedLinks = [
	{ title: 'HESAV', url: '/fr/projets/hesav-haute-ecole-vaudoise-sante/' },
];

// --------------------------------
// #endregion
// --------------------------------

const ProjectMjfPage = (props) => {
	const data = useStaticQuery(
		graphql`
			query {
				heroImage: file(
					relativePath: { eq: "projects/debiopharm/debio_hero.jpg" }
				) {
					childImageSharp {
						fluid(maxWidth: 1920, quality: 100) {
							...GatsbyImageSharpFluid_withWebp
						}
					}
				}
				heroImageMobile: file(
					relativePath: {
						eq: "projects/debiopharm/debio_hero_mobile.jpg"
					}
				) {
					childImageSharp {
						fluid(maxWidth: 1920, quality: 100) {
							...GatsbyImageSharpFluid_withWebp
						}
					}
				}
				image_1: file(
					relativePath: { eq: "projects/debiopharm/debio_1.png" }
				) {
					childImageSharp {
						fluid(maxWidth: 1920, quality: 100) {
							...GatsbyImageSharpFluid_withWebp
						}
					}
				}
				image_2: file(
					relativePath: { eq: "projects/debiopharm/debio_2.png" }
				) {
					childImageSharp {
						fluid(maxWidth: 1920, quality: 100) {
							...GatsbyImageSharpFluid_withWebp
						}
					}
				}
				mjf_3: file(relativePath: { eq: "projects/mjf/mjf_3.jpg" }) {
					childImageSharp {
						fluid(maxWidth: 660, quality: 100) {
							...GatsbyImageSharpFluid_withWebp
						}
					}
				}
				mjf_4: file(relativePath: { eq: "projects/mjf/mjf_4.jpg" }) {
					childImageSharp {
						fluid(maxWidth: 660, quality: 100) {
							...GatsbyImageSharpFluid_withWebp
						}
					}
				}
				mjf_5: file(relativePath: { eq: "projects/mjf/mjf_5.jpg" }) {
					childImageSharp {
						fluid(maxWidth: 1270, quality: 100) {
							...GatsbyImageSharpFluid_withWebp
						}
					}
				}
				mjf_6: file(relativePath: { eq: "projects/mjf/mjf_6.jpg" }) {
					childImageSharp {
						fluid(maxWidth: 1270, quality: 100) {
							...GatsbyImageSharpFluid_withWebp
						}
					}
				}
				mjf_7: file(relativePath: { eq: "projects/mjf/mjf_7.jpg" }) {
					childImageSharp {
						fluid(maxWidth: 1270, quality: 100) {
							...GatsbyImageSharpFluid_withWebp
						}
					}
				}
				mjf_8: file(relativePath: { eq: "projects/mjf/mjf_8.jpg" }) {
					childImageSharp {
						fluid(maxWidth: 800, quality: 100) {
							...GatsbyImageSharpFluid_withWebp
						}
					}
				}
				mjf_9: file(relativePath: { eq: "projects/mjf/mjf_9.jpg" }) {
					childImageSharp {
						fluid(maxWidth: 800, quality: 100) {
							...GatsbyImageSharpFluid_withWebp
						}
					}
				}
				mjf_10: file(relativePath: { eq: "projects/mjf/mjf_10.jpg" }) {
					childImageSharp {
						fluid(maxWidth: 1920, quality: 100) {
							...GatsbyImageSharpFluid_withWebp
						}
					}
				}
			}
		`
	);

	return (
		<Layout
			language={language}
			pageClass={pageClass}
			footerRelatedLinks={footerRelatedLinks}
			accentColor={accent_color}
			location={props.location}
			translations={[{ uri: '/en/projects/debiopharm/', lang: 'en' }]}
			title="Debiopharm - Par Superhuit"
		>
			<HeroProject
				breadcrumb={{
					title: 'Projets',
					link: '/fr/projets/',
				}}
				title="Debiopharm"
				// TODO: replace with this videoCode e9711ce590c51e1fc6a807beb8a7a56c
				image={{
					...data.heroImage.childImageSharp,
				}}
				imageMobile={{
					...data.heroImageMobile.childImageSharp,
				}}
			/>
			<section className="grid grid-content" aria-label="Project">
				<div className="project-intro" data-animation-page>
					<div className="project-summary text-content">
						<p className="p-medium">
							Développer les meilleurs traitements en se
							focalisant sur les besoins réels des patients.
						</p>

						<ListText
							className="element-spacing"
							title="Nos services"
							list={[
								'Stratégie',
								'Contenus créatifs',
								'Site web',
								'Design visuel',
							]}
						/>
					</div>
					<div className="project-description text-content">
						<h3>Qui</h3>
						<p>
							Depuis plus de 40 ans, Debiopharm se consacre au
							développement de nouveaux médicaments. Constamment à
							la recherche de meilleurs traitements pour les
							patients. L'évolution stratégique du groupe l'a
							poussé à repenser la manière de se présenter en
							intégrant superhuit dans la réflexion pour
							redynamiser son image de marque et refondre
							totalement sa présence digitale.{' '}
						</p>
						<h3>Quoi</h3>
						<p>
							L'équipe de projet a restructuré l'information de
							manière à faciliter la compréhension de ce qu'est
							Debiopharm et quelles sont les différentes activités
							de la société. Nous avons fait évoluer la marque
							pour qu'elle se recentre sur l'humain en le plaçant
							au premier plan. La direction artistique nous a
							amené à rechercher une expression graphique raffinée
							et sobre.
						</p>
						<div className="project-links text-content">
							<ButtonExternal
								href="https://www.debiopharm.com/"
								text="Voir le site"
							/>
						</div>
					</div>
				</div>

				<Video
					className="col-full"
					src="45ed6ca3eef7b6988062436c114572a9"
					alt="Animation Homepage"
				/>
				<Column>
					<div className="text-content column__sticky column__text">
						<h3>
							Une rythmique graphique en équilibre entre le
							dynamisme et la tranquillité
						</h3>
						<p>
							La marque utilise un rouge vif qui rappelle son
							histoire à la fois suisse et japonaise, son
							intégration minutieuse dans l'ensemble des
							interfaces apporte structure et dynamisme.{' '}
						</p>
					</div>
					<div className="text-content column__media">
						<Image
							{...data.image_1.childImageSharp}
							alt="Typography"
						/>
						<Image
							{...data.image_2.childImageSharp}
							alt="Typography"
						/>
					</div>
				</Column>

				<Video
					className="col-full"
					src="62b52b63acb499eb60a228581bdec114"
					alt="Menu"
				/>

				{/*
				<Blockquote
					modifiers="big"
					uptitle="Client Testimony"
					quote="superhuit were a true strategic partner for the development of a new digital strategy for Montreux Jazz Festival and its business universe."
					more="They provided fun and engaging workshops, 1:1 sessions to devise and simplify a complex multichannel, digital portfolio. This has resulted in a beautiful, progressive website experiences that can grow with the needs of a new media business. It is a very committed, passionate partnership based on trust, expertise with creatively at the heart of everything we do together."
					cite="Michelle Goff, Digital Specialist &amp; Project Lead"
				/> */}

				<Column modifiers="left">
					<div className="column__media text-content">
						<Video
							src="7bfcc789a90b9c64fc32886f5e50b4a1"
							alt="Plateforme Média"
						/>
					</div>

					<div className="column__text column__sticky text-content">
						<h3>
							Une structure de l'information pensée pour tous les
							contextes
						</h3>
						<p>
							Chaque contexte a été considéré afin de proposer une
							expérience fluide que l'utilisateur soit sur un
							téléphone mobile, une tablette comme un ordinateur.
						</p>
					</div>
				</Column>

				{/* <Cta
					subscribe
					text="Be the first to know when we release a new project"
					cta="Subscribe"
					currentPage="Project"
				/> */}
			</section>
		</Layout>
	);
};

export default ProjectMjfPage;
